import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class SuppliersService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  getRegistrationRequestList(urlQuery) {
    return this.http.get<any>(`${this.serverUrl}supplier/search${urlQuery}`).pipe(map((res) => {
      return res;
    }));
  }
  getSupplierById(supplierId) {
    return this.http.get<any>(`${this.serverUrl}supplier/${supplierId}`).pipe(map((res) => {
      return res;
    }));
  }

  uploadLogo(supplierId, logoFile) {
    const input = new FormData();
    input.append('file', logoFile);
    return this.http.put(`${this.serverUrl}supplier/${supplierId}/uploadLogo`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadAuthorizedSignatoryId(supplierId, doc) {
    const input = new FormData();
    input.append('file', doc);
    return this.http.put(`${this.serverUrl}supplier/${supplierId}/uploadAuthorizedSignatoryId`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadLegalRegistration(supplierId, doc) {
    const input = new FormData();
    input.append('file', doc);
    return this.http.put(`${this.serverUrl}supplier/${supplierId}/uploadLegalRegistration`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  update(objForSave) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}supplier/`, JSON.stringify(objForSave), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
