import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class ReviewsService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  getAllReviews(urlQuery) {
    return this.http.get<any>(`${this.serverUrl}review/all/${urlQuery}`).pipe(map((res) => {
      return res;
    }));
  }

  updateStatusReview(reviewId, newStatus) {
    return this.http.post(`${this.serverUrl}review/${reviewId}/changeStatusTo${newStatus}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
