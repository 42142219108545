import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';
import {TourSessionModel} from '../models/tourSession.model';

@Injectable()
export class ToursService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }
  getAllTours(urlQuery) {
    return this.http.get<any>(`${this.serverUrl}tour/all${urlQuery}`).pipe(map((res) => {
      return res;
    }));
  }
  addTourStep1(tour) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post<any>(`${this.serverUrl}tour/`, JSON.stringify(tour), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  updateTour(tour) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}tour/`, JSON.stringify(tour), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  uploadImage(tourId, image) {
    const input = new FormData();
    input.append('image', image);
    return this.http.post(`${this.serverUrl}tour/${tourId}/uploadImage`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  updateLandmarks(tourId, landmarkIdsList) {
    const input = new FormData();
    input.append('landmarkIdsList', landmarkIdsList);
    return this.http.put(`${this.serverUrl}tour/${tourId}/landmark`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  updateStatusTour(tourId, newStatus) {
    return this.http.put(`${this.serverUrl}tour/${tourId}/changeStatusTo${newStatus}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getTourById(tourId: string) {
    return this.http.get<any>(`${this.serverUrl}tour/${tourId}`).pipe(map((res) => {
      return res;
    }));
  }

  publicGetTourById(tourId: string) {
    const options = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'true',
      }),
    };
    return this.http.get<any>(`${this.serverUrl}public/tour/${tourId}`, options).pipe(map((res) => {
      return res;
    }));
  }

  getTourSessions(tourId: string) {
    return this.http.get<any>(`${this.serverUrl}tour/sessions/${tourId}`).pipe(map((res) => {
      return res;
    }));
  }

  createTourSessions(tourId: string, sessions: [TourSessionModel]) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}tour/sessions/${tourId}`, JSON.stringify(sessions), config)
      .pipe(map((res) => {
        return res;
        }),
      );
  }
  removeTourSession(tourId: string, sessionId: string) {
    return this.http.delete(`${this.serverUrl}tour/sessions/${tourId}`, {params: {tourSessionIds: sessionId}})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  removeTour(tourId: string) {
    return this.http.delete(`${this.serverUrl}tour/${tourId}`)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
  getTourLandmarks(tourId: string) {
    return this.http.get<any>(`${this.serverUrl}tour/${tourId}/landmark`).pipe(map((res) => {
      return res;
    }));
  }

  getTourLandmarkDetails(tourId: string, landmarkId: string) {
    return this.http.get<any>(`${this.serverUrl}tour/${tourId}/landmark/${landmarkId}/metadata`).pipe(map((res) => {
      return res;
    }));
  }

  removeTourLandmarkDetail(tourId: string, landmarkId: string, detailId: string) {
    return this.http.delete<any>(`${this.serverUrl}tour/${tourId}/landmark/${landmarkId}/metadata/${detailId}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  addTourLandmarkDetail(tourId: string, landmarkId: string, title: string, description: string,
                        order: string, mediaFile) {
    const input = new FormData();
    input.append('title', title);
    input.append('description', description);
    input.append('mediaFile', mediaFile);
    if (order) input.append('order', order);
    return this.http.post(`${this.serverUrl}tour/${tourId}/landmark/${landmarkId}/metadata`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateTourLandmarkDetail(tourId: string, landmarkId: string, metadataId: string,
                           title: string, description: string, order: string, mediaFile) {
    const input = new FormData();
    if (title) input.append('title', title);
    if (description) input.append('description', description);
    if (order) input.append('order', order);
    if (mediaFile) input.append('mediaFile', mediaFile);
    return this.http.put(`${this.serverUrl}tour/${tourId}/landmark/${landmarkId}/metadata/${metadataId}`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getTicketsInSession(sessionId: string) {
    return this.http.get<any>(`${this.serverUrl}ticket/tourSession/${sessionId}`).pipe(map((res) => {
      return res;
    }));
  }

  printTicketInSession(ticketId: string) {
    return this.http.get<any>(`${this.serverUrl}ticket/${ticketId}/print`).pipe(map((res) => {
      return res;
    }));
  }

  previewAllTicketsInSession(sessionId: string) {
    return this.http.get<any>(`${this.serverUrl}ticket/tourSession/${sessionId}/print?preview=true&justAvailable=false`,
      {responseType: 'blob' as 'json'},
    ).pipe(map((res) => {
      return res;
    }));
  }

  previewTicketInSession(ticketId: string) {
    return this.http.get<any>(`${this.serverUrl}ticket/${ticketId}/print?preview=true`,
      {responseType: 'blob' as 'json'},
    ).pipe(map((res) => {
      return res;
    }));
  }

  printAllTicketsInSession(sessionId: string) {
    return this.http.get<any>(`${this.serverUrl}ticket/tourSession/${sessionId}/print`).pipe(map((res) => {
      return res;
    }));
  }
}
