import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'excerpt',
})
export class ExcerptPipe implements PipeTransform {
  transform(text: string, limit?: number) {
    if (!text) return null;
    const desiredLimit = (limit) ? limit : 20;
    return text.substr(0, desiredLimit) + (text.length > limit ? '...' : '');
  }
}

// {{ text | excerpt : 10 }}
