import {NgModule} from '@angular/core';
import {
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbTreeGridModule,
  NbSelectModule, NbCheckboxModule, NbSpinnerModule, NbLayoutModule,
} from '@nebular/theme';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {ThemeModule} from '../../@theme/theme.module';
import {AgGridModule} from 'ag-grid-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import {TagInputModule} from 'ngx-chips';
import {AppPipesModule} from '../../app-pipes.module';
import {NgxCustomAuthRoutingModule , routedComponents} from './custom-auth-routing.module';
import {NbAuthModule} from '@nebular/auth';
import {CustomAuthLoginRegisterComponent} from './login-register/custom-auth-login-register.component';

@NgModule({
  imports: [
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    NgxCustomAuthRoutingModule,
    Ng2SmartTableModule,
    AgGridModule,
    NbButtonModule,
    ReactiveFormsModule,
    NbSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDCr-Z0V7Yp5Nu4EiuEwRnbnggcABC3dHM',
      libraries: ['places'],
    }),
    FormsModule,
    TagInputModule,
    NbCheckboxModule,
    NbSpinnerModule,
    AppPipesModule,
    NbLayoutModule,
    NbAuthModule,
  ],
  declarations: [
    CustomAuthLoginRegisterComponent,
    ...routedComponents,
  ],
  entryComponents: [
  ],
})
export class CustomAuthModule {
}
