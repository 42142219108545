import {ExcerptPipe} from './shared/pipes/excerpt.pipe';
import {NgModule} from '@angular/core';
import {RoundPipe} from './shared/pipes/round.pipe';
import {SecurePipe} from './shared/pipes/secure.pipe';
import {ExportNumber} from './shared/pipes/exportNumber.pipe';
import {SafePipe} from './shared/pipes/safe.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    ExcerptPipe,
    RoundPipe,
    SecurePipe,
    ExportNumber,
    SafePipe,
  ],
  exports: [
    ExcerptPipe,
    RoundPipe,
    SecurePipe,
    ExportNumber,
    SafePipe,
  ],
})
export class AppPipesModule {
}
