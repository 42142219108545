import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Observer, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {DeviceInfoModel} from '../models/deviceInfo.model';

@Injectable()
export class AuthApiService {
  serverUrl = this.gloarConfig.params.serverUrl;
  errorData: {};
  authorization = this.gloarConfig.params.authorizationKey;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  save(callback: (n: number) => any): void {
    callback(42);
  }

  redirectUrl: string;

  login(email: string, password: string) {
    const loginObservable = new Observable((observer: Observer<any>) => {
      const getOauthToken = this.getOauthToken(email, password).subscribe((oauth) => {
        if (oauth && oauth.access_token) {
          oauth.expires_at = +Math.floor(Date.now() / 1000) + +oauth.expires_in;
          this.gloarConfig.CryptoJSSetStorage('userAuthorization', JSON.stringify(oauth));
          // --------------------------------------------------------
          const checkAccessToken = this.checkAccessToken().subscribe((res1) => {
            this.gloarConfig.CryptoJSSetStorage('userInfo', JSON.stringify(res1));
            // --------------------------------------------------------
            const saveUserPermissions = this.saveUserPermissions().subscribe((res2) => {
                const save = this.gloarConfig.CryptoJSSetStorage('userInfo2', JSON.stringify(res2));
                if (res2 && save === true) {
                  observer.next(1);
                  observer.complete();
                }
              }, error => {
                observer.error(error.error.message);
              },
              () => {
              });
          }, error => {
            observer.error(error.error.message);
          }, () => {
          });
        }
      }, error => {
        observer.error(error.error.message);
      }, () => {
      });
    });
    return loginObservable;
  }

  getOauthToken(email: string, password: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': this.authorization,
        'X-Skip-Interceptor': 'true',
      }),
    };
    const body = new HttpParams()
      .set('username', email)
      .set('password', password)
      .set('grant_type', 'password')
    ;
    return this.http.post<any>(`${this.serverUrl}oauth/token`, body.toString(), options).pipe(map((res) => {
      return res;
    }));
  }
  isLoggedIn() {
    const userAuthorization = JSON.parse(this.gloarConfig.CryptoJSGetStorage('userAuthorization'));
    if (userAuthorization && +Math.floor(Date.now() / 1000) > userAuthorization.expires_at) {
      return this.logout();
    }
    if (userAuthorization && userAuthorization.access_token) return true;
    return false;
  }
  checkAccessToken() {
    const userAuthorization = JSON.parse(this.gloarConfig.CryptoJSGetStorage('userAuthorization'));
    const accessToken = userAuthorization.access_token;
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + accessToken,
        'Content-Type': 'application/json',
      }),
    };
    const deviceInfo = new DeviceInfoModel();
    deviceInfo.id = '';
    deviceInfo.model = 'panel';
    deviceInfo.os = '';
    deviceInfo.osVersion = '';
    deviceInfo.appVersion = '1.1';
    deviceInfo.carrier = '';
    deviceInfo.networkType = '';
    deviceInfo.ip = '';
    return this.http.post(`${this.serverUrl}user/signIn`, JSON.stringify(deviceInfo), options).pipe(map((res) => {
      return res;
    }));
  }
  getAuthorizationToken() {
    const userAuthorization = JSON.parse(this.gloarConfig.CryptoJSGetStorage('userAuthorization'));
    return userAuthorization ? userAuthorization.access_token : false;
  }
  logout() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userAuthorization');
    localStorage.removeItem('userInfo2');
    this.router.navigate(['/auth/login']);
    return false;
  }
  saveUserPermissions() {
    return this.http.get(`${this.serverUrl}profile/permissions`, {}).pipe(map((res) => {
      return res;
    }));
  }
}

