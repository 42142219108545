import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {Injectable} from '@angular/core';

@Injectable()
export class SystemPreferencesService {

  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getPreferences() {
    return this.http.get<any>(`${this.serverUrl}preferences/`).pipe(map((res) => {
      return res;
    }));
  }

  setPreferences(params) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}preferences/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  setPreferencesForNotification(params) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}preferences/pn`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getFaqList() {
    return this.http.get<any>(`${this.serverUrl}faq/list/`).pipe(map((res) => {
      return res;
    }));
  }

  addFaq(params) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}faq/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeFaq(faqId: string) {
    return this.http.delete(`${this.serverUrl}faq/${faqId}`)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeActivitySubCategory(cat: string, subCat: string) {
    return this.http.delete(`${this.serverUrl}preferences/activity/category/${cat}/${subCat}`)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  addActivitySubCategory(cat: string, subCat: string) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}preferences/activity/category/${cat}/${subCat}`, '', config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
