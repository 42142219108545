import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';
@Injectable()
export class UsersService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router,
              private gloarConfig: GloarConfig) {
  }

  getAllUsers(urlQuery) {
    return this.http.get(`${this.serverUrl}profile/list${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  getUser(userId) {
    return this.http.get(`${this.serverUrl}profile/${userId}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  blockUser(userId) {
    return this.http.post<any>(`${this.serverUrl}profile/${userId}/block`, {}).pipe(map((res) => {
      return res;
    }));
  }

  unBlockUser(userId) {
    return this.http.post<any>(`${this.serverUrl}profile/${userId}/unblock`, {}).pipe(map((res) => {
      return res;
    }));
  }

  addUser(params) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}profile/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateUser(params) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}profile/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  changePasswordMyUser(oldPassword, newPassword) {
    const input = new FormData();
    input.append('oldPassword', oldPassword);
    input.append('newPassword', newPassword);
    return this.http.put(`${this.serverUrl}profile/changePassword`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateAvatar(image) {
    const input = new FormData();
    input.append('image', image);
    return this.http.post(`${this.serverUrl}profile/uploadAvatar`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  validateUsername(username) {
    const input = new FormData();
    input.append('username', username);
    return this.http.post(`${this.serverUrl}profile/validateUsername`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  validateEmail(username) {
    const input = new FormData();
    input.append('email', username);
    return this.http.post(`${this.serverUrl}profile/validateEmail`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  refreshUserInfo() {
    const userId = this.gloarConfig.userInfo().id;
    this.getUser(userId).subscribe((data: any) => {
      this.gloarConfig.CryptoJSSetStorage('userInfo', JSON.stringify(data));
    }, error => {
      this.gloarConfig.checkSubscribeError(error);
    }, () => {
    });
  }
}
