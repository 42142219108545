import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class DashboardReportService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  mainReport(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboardReports/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  supplierTotalViewsCount(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/supplierTotalViewsCount/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  supplierTotalReviewsCount(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/supplierTotalReviewsCount/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  supplierTotalActivitiesCount(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/supplierTotalActivitiesCount/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  topActivities(number: number, urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/top${number}Activities/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }


  activityViewsSeries(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/series/activityViews/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  activityReviewsSeries(urlQuery) {
    return this.http.get(`${this.serverUrl}dashboard/business/series/activityReviews/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  supplierCheckProfile(urlQuery) {
    return this.http.get(`${this.serverUrl}supplier/checkProfile/?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }
}
