import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomAuthLoginRegisterComponent} from './login-register/custom-auth-login-register.component';


export const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: 'login-register',
        component: CustomAuthLoginRegisterComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxCustomAuthRoutingModule {
}

export const routedComponents = [];
