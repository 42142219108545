import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';
import {LandmarkModel} from '../models/landmark.model';

@Injectable()
export class CustomAuthService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig) {}

  supplierRegister(data: any) {
    // ==============
    const options = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'true',
        'Content-Type': 'application/json',
      }),
    };
    // =============
    return this.http.post<any>(`${this.serverUrl}supplier/register`, JSON.stringify(data), options)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
