export class DeviceInfoModel {
  id: string;
  model: string;
  os: string;
  osVersion: string;
  appVersion: string;
  carrier: string;
  networkType: string;
  ip: string;
}
