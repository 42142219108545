import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class DateTimeGeneratorService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  dateGenerator(Query) {
    let httpParams = new HttpParams();
    Object.keys(Query).forEach(function (key) {
      httpParams = httpParams.append(key, Query[key]);
    });
    return this.http.post(`${this.serverUrl}generator/time?${httpParams.toString()}`, '')
      .pipe(map((res) => {
        return res;
      }));
  }
}
