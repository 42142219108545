import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class CompaniesService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  createCompany(company) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}company/`, JSON.stringify(company), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateCompany(company) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}company/`, JSON.stringify(company), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }


  getAllCompanies(urlQuery) {
    return this.http.get(`${this.serverUrl}company/all?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  removeCompany(companyId) {
    return this.http.delete(`${this.serverUrl}company/${companyId}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getCompanyById(companyId: string) {
    return this.http.get<any>(`${this.serverUrl}company/${companyId}`).pipe(map((res) => {
      return res;
    }));
  }

  uploadImage(companyId, image) {
    const input = new FormData();
    input.append('logo', image);
    return this.http.post(`${this.serverUrl}company/logo/${companyId}`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
