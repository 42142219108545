import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class ActivitiesScraperImageReviewService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getAllImageSelectionList(urlQuery) {
    return this.http.get(`${this.serverUrl}activity/imageSelectionList${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  persistFromExternalSource(activityId, data) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}activity/${activityId}/persistFromExternalSource`,
      JSON.stringify(data), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
