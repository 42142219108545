import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class ActivitiesService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getAllActivities(urlQuery) {
    return this.http.get(`${this.serverUrl}activity/search${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  getActivity(id: string) {
    return this.http.get<any>(`${this.serverUrl}activity/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  addActivity(params: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}activity/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateActivity(params: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}activity/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeActivity(id: string) {
    return this.http.delete<any>(`${this.serverUrl}activity/${id}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  addActivityStep1(params: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}activity/`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadImage(activityId, image) {
    const input = new FormData();
    input.append('imageFile', image);
    return this.http.post(`${this.serverUrl}activity/${activityId}/uploadImage`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadGallery(activityId, image) {
    const input = new FormData();
    for (const item of image) {
      input.append('imageFiles', item);
    }
    return this.http.post(`${this.serverUrl}activity/${activityId}/uploadGallery`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeImageFromGallery(activityId: string, imageId: string) {
    return this.http.delete(`${this.serverUrl}activity/${activityId}/deleteGallery/${imageId}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  calculateCovidRisk(params: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}activity/calculateCovidRisk`, JSON.stringify(params), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getSubCategories(category) {
    return this.http.get<any>(`${this.serverUrl}preferences/`).pipe(map((res) => {
      return res;
    }));
  }

  changeStatus(activityId, newStatus) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}activity/${activityId}/changeTo${newStatus}`, '', config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  categoryList() {
    return [
      {'name': 'Sports', 'display': 'Sports', 'value': 'SPORTS', 'img': 'sports.svg'},
      {'name': 'Adventures', 'display': 'Adventures', 'value': 'ADVENTURES', 'img': 'adventures.svg'},
      {'name': 'Sightseeing', 'display': 'Sightseeing', 'value': 'SIGHTSEEING', 'img': 'sightseeing.svg'},
      {'name': 'Water Sports', 'display': 'Water Sports', 'value': 'WATER_SPORTS', 'img': 'watersports.svg'},
      {'name': 'Museums', 'display': 'Museums', 'value': 'MUSEUMS', 'img': 'museum.svg'},
      {'name': 'Stay Cations', 'display': 'Stay Cations', 'value': 'STAY_CATIONS', 'img': 'staycation.svg'},
      {'name': 'Nature Parks', 'display': 'Nature Parks', 'value': 'NATURE_PARKS', 'img': 'nature.svg'},
      {'name': 'Malls Shopping', 'display': 'Malls Shopping', 'value': 'MALLS_SHOPPING', 'img': 'shopping.svg'},
      {'name': 'Family Games', 'display': 'Family Games', 'value': 'FAMILY_GAMES', 'img': 'family.svg'},
      {'name': 'Spas Wellness', 'display': 'Spas Wellness', 'value': 'SPAS_WELLNESS', 'img': 'spas.svg'},
      {'name': 'Events Culture', 'display': 'Events Culture', 'value': 'EVENTS_CULTURE', 'img': 'events2.svg'},
      {'name': 'Nightlife', 'display': 'Nightlife', 'value': 'NIGHTLIFE', 'img': 'nightlife.svg'},
      {'name': 'Religious Services', 'display': 'Religious Services', 'value': 'RELIGIOUS_SERVICES', 'img': 'religious_services.svg'},
    ];
  }
}
