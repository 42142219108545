import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {AuthApiService} from '../services/authApiService.service';
import {Observable} from 'rxjs';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthApiService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*const urlParams = new URLSearchParams(req.urlWithParams);
    const skipAuthorization: string = urlParams.get('skipAuthorization');
    if (skipAuthorization === 'yes') return next.handle(req);*/
    // =======
    if (req.headers.get(InterceptorSkipHeader)) return next.handle(req);
    if (this.authService.isLoggedIn()) {
      const authToken = this.authService.getAuthorizationToken();
      req = req.clone({setHeaders: {Authorization: 'bearer ' + authToken}});
    }
    return next.handle(req);
  }
}
