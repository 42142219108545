import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class VouchersService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getAllVouchers(urlQuery) {
    return this.http.get(`${this.serverUrl}voucher/all${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  addVoucher(zoneId, validFrom: string,
             validTo: string, count: string, value: string, applyTo: string, type: string) {
    const input = new FormData();
    input.append('validFrom', validFrom);
    input.append('validTo', validTo);
    input.append('count', count);
    input.append('value', value);
    input.append('applyTo', applyTo);
    input.append('type', type);
    input.append('zoneId', zoneId);
    return this.http.post(`${this.serverUrl}generator/voucher`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeVoucher(voucherCode: string) {
    return this.http.delete<any>(`${this.serverUrl}voucher/${voucherCode}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  blockVoucher(voucherCode: string) {
    return this.http.put<any>(`${this.serverUrl}voucher/makeBlock/${voucherCode}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }
}
