import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class EurekaAppsService {
  serverSpringEurekaUrl = this.gloarConfig.params.serverSpringEurekaUrl;

  constructor(
    private http: HttpClient,
    protected router: Router,
    private gloarConfig: GloarConfig) {
  }

  getAllApps(urlQuery) {
    const config = {
      headers:
        new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json'),
    };
    return this.http.get(`${this.serverSpringEurekaUrl}eureka/apps/${urlQuery}`, config)
      .pipe(map((res) => {
        return res;
      }));
  }

  getActuatorHealth(url, urlQuery) {
    const config = {
      headers:
        new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json'),
    };
    return this.http.get(`https://${url}:8443/api/actuator/health/${urlQuery}`, config)
      .pipe(map((res) => {
        return res;
      }));
  }
}
