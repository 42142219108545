import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {
  NbAuthComponent,
} from '@nebular/auth';
import {AuthGuard} from './shared/services/authGuard.service';
// tslint:disable-next-line:max-line-length
import {CustomAuthLoginRegisterComponent} from './pages/custom-auth/login-register/custom-auth-login-register.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: CustomAuthLoginRegisterComponent,
    loadChildren: './pages/auth/auth.module#NgxAuthModule',
  },
  {
    path: 'public',
    // component: PublicComponent,
    loadChildren: './pages/public/public.module#NgxPublicModule',
  },
  {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
