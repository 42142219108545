import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class GlobalService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  uploadTemporary(type, file) {
    const input = new FormData();
    input.append('file', file);
    // ==============
    const options = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'true',
      }),
    };
    // ==============
    return this.http.post<any>(
      `${this.serverUrl}resources/uploadTemporary?type=${type}`, input, options)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  currencies() {
    return [
      {'code' : 'USD', 'symbol': '$', 'multiplier': 1},
      {'code' : 'AED', 'symbol': 'AED', 'multiplier': 1},
      {'code' : 'EUR', 'symbol': '€', 'multiplier': 1},
    ];
  }

}
