import * as CryptoJS from 'crypto-js';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class GloarConfig {
  params = {
    serverSpringEurekaUrl: 'https://epicreality.app:8761/',
    serverUrl : 'https://api.epicreality.app/api/',
    // serverUrl : 'https://api.dndev.ir/api/',
    authorizationKey: 'Basic ZXBpY3JlYWxpdHktY2xpZW50Ojc1NEM5MEUzREYxN0IyMTk3NDUzQUEwRDk2OTZENTE4REMxRUFBRkM=',
    cryptoJSSecretKey: 'p49ORIKgZ2jj1NqdLO#rzqs6if@W9c&mE!QkT9DO!S8fd6J2$AxyGHi*cyYUxC6Nkb19M',
  };

  constructor(public router?: Router) {}

  CryptoJSSetStorage(key, value) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), this.params.cryptoJSSecretKey).toString();
    window.localStorage.setItem(key, encryptedData);
    return true;
  }

  CryptoJSGetStorage(key) {
    const getItem = localStorage.getItem(key);
    if (!getItem) return false;
    const bytes = CryptoJS.AES.decrypt(getItem.toString(), this.params.cryptoJSSecretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  userRoles() {
    return JSON.parse(this.CryptoJSGetStorage('userInfo'))['roles'];
  }

  userInfo() {
    return JSON.parse(this.CryptoJSGetStorage('userInfo'));
  }

  userPermissions(from = false) {
    const userInfo2 = this.CryptoJSGetStorage('userInfo2') ;
    return JSON.parse(userInfo2);
  }

  checkSubscribeError(error: any) {
    if (error.status === 401) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userAuthorization');
      localStorage.removeItem('userInfo2');
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
