import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs/Observable';
import 'rxjs-compat/add/operator/map';
import {AuthApiService} from '../services/authApiService.service';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private authService: AuthApiService) {
  }

  transform(url): Observable<SafeUrl> {
    const authToken = this.authService.getAuthorizationToken();
    return this.http
      .get(url, {headers: {Authorization: 'bearer ' + authToken}, responseType: 'blob'})
      .map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)));
  }

}
