import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';
import {LandmarkModel} from '../models/landmark.model';

@Injectable()
export class CloudMessagingService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  pushTemplated(data: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post<any>(`${this.serverUrl}push/templated`, JSON.stringify(data), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  pushRegisteredCount(urlQuery: any) {
    return this.http.get(`${this.serverUrl}clientInfo/pushRegistered/count?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  typesList() {
    return [
      {name: 'Whole Promotion', value: 'WHOLE_PROMOTION'},
      {name: 'App Update', value: 'APP_UPDATE'},
      {name: 'Dummy', value: 'DUMMY'},
      {name: 'Activity', value: 'ACTIVITY'},
      {name: 'Activity Category', value: 'ACTIVITY_CATEGORY'},
      {name: 'Web Url', value: 'WEB_URL'},
    ];
  }

}
