import {Component, OnInit} from '@angular/core';
import {NbAuthComponent, NbAuthService} from '@nebular/auth';
import {ToursService} from '../../../shared/services/tours.service';
import {Form, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {GloarConfig} from '../../../shared/config/gloar.config';
import {ActivatedRoute, Router} from '@angular/router';
import {LandmarksService} from '../../../shared/services/landmarks.service';
import {SystemPreferencesService} from '../../../shared/services/systemPreferences.service';
import {ActionsApiProvidesService} from '../../../shared/services/actionsApiProvides.service';
import {AddressesService} from '../../../shared/services/addresses.service';
import {Location} from '@angular/common';
import {GlobalService} from '../../../shared/services/global.service';
import {CustomAuthService} from '../../../shared/services/customAuth.service';

@Component({
  selector: 'ngx-custom-auth',
  templateUrl: './custom-auth-login-register.component.html',
  styleUrls: ['./custom-auth-login-register.component.scss'],
})
export class CustomAuthLoginRegisterComponent extends NbAuthComponent implements OnInit {
  step: number = 0;
  termAccept = false;
  logo: File = null;
  logoPreviewUrl: any = false;
  logoPreviewUrl2: any = false;
  ImageUpload: any = false;
  business_category =  [
    {value: 'ACTIVITIES', display: 'Activities'},
    {value: 'TOURS', display: 'Tours'},
    {value: 'EVENTS', display: 'Events'},
  ];
  businessCategorySelected: any = ['ACTIVITIES', 'TOURS', 'EVENTS'];
  loading  = false;
  legalRegistrationDocument;
  authorizedSignatoryIdDocument;
  fileLegalRegistrationDocument:  any = false;
  fileAuthorizedSignatoryIdDocument: any = false;
  form1: FormGroup;
  form2: FormGroup;
  objectForSave: any;
  addressTree: any;
  addressStatesTree: any;
  addressCitiesTree: any;
  countrySelected: any;
  stateSelected: any;
  citySelected: any;
  // =================================================================
  formData: any;
  showErrorsInForm1 = false;
  showErrorsInForm2 = false;
  constructor(
    auth: NbAuthService, location: Location,
    private toursService: ToursService, private fb: FormBuilder,
    private globalService: GlobalService,
    private customAuthService: CustomAuthService,
    private toastrService: NbToastrService, private gloarConfig: GloarConfig,
    private router: Router, private  landmarksService: LandmarksService,
    private dialogService: NbDialogService,
    private activatedRoute: ActivatedRoute,
    private systemPreferencesService: SystemPreferencesService,
    private actionsApiProvidesService: ActionsApiProvidesService,
    private addressesService: AddressesService , private gloar: GloarConfig) {
    super(auth, location);
  }
  ngOnInit() {
    this.form1 = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirm: [''],
      name: ['', Validators.required],
      about: [''],
    });
    this.form2 = this.fb.group({
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      phone: ['', Validators.required],
      mobile: [''],
      fax: [''],
      website: ['', [Validators.required, Validators.pattern('([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      social_linkedin: [''],
      social_facebook: [''],
      social_instagram: [''],
    });
    // ===========
    this.getAddressTree();
  }
  changeStep(number: number) {
    this.step = number;
  }

  termCheckBox($event: Event) {
    this.termAccept = $event.target['checked'];
  }
  logoProgress(fileInput: any) {
    this.logo = <File>fileInput.target.files[0];
    const mimeType = this.logo.type;
    if (mimeType.match(/image\/*/) == null) {
      return this.toastrService.show('Upload failed, Please select an image file', 'Error : ', {status: 'danger'});
    }
    this.logoPreviewUrl = false;
    this.logoPreviewUrl2 = false;
    // ======
    const reader = new FileReader();
    reader.readAsDataURL(this.logo);
    reader.onload = (_event) => {
      this.logoPreviewUrl = reader.result;
      this.logoPreviewUrl2 = false;
    };
    this.loading = true;
    this.globalService.uploadTemporary('SUPPLIER_LOGO', this.logo).subscribe((res) => {
        this.ImageUpload = res;
        this.toastrService.show('Image uploaded successfully', 'Success : ', {status: 'success'});
      },
      (error) => {
        this.gloar.checkSubscribeError(error);
        this.loading = false;
        this.ImageUpload = false;
        this.toastrService.show(error.error.message, 'Error : ', {status: 'danger'});
      },
      () => {
        this.loading = false;
      },
    );
  }
  documentsUpload(type, fileInput: any) {
    let this_file ;
    if (type === 'LEGAL_REGISTRATION_DOCUMENT') {
      this.fileLegalRegistrationDocument =  <File>fileInput.target.files[0];
      this_file =  this.fileLegalRegistrationDocument ;
    } else {
      this.fileAuthorizedSignatoryIdDocument =  <File>fileInput.target.files[0];
      this_file =  this.fileAuthorizedSignatoryIdDocument ;
    }
    this.loading = true;
    this.globalService.uploadTemporary(type, this_file).subscribe((res) => {
        if (type === 'LEGAL_REGISTRATION_DOCUMENT') {
          this.legalRegistrationDocument = res;
        } else {
          this.authorizedSignatoryIdDocument = res;
        }
        this.toastrService.show('Document uploaded successfully', 'Success : ', {status: 'success'});
      },
      (error) => {
        this.gloar.checkSubscribeError(error);
        this.loading = false;
        this.ImageUpload = false;
        this.toastrService.show(error.error.message, 'Error : ', {status: 'danger'});
      },
      () => {
        this.loading = false;
      },
    );
  }

  onForm1Submit() {
    this.showErrorsInForm1 = true;
    if (!this.form1.valid) return '';
    // ===============================
    if (this.form1.get('password').value !== this.form1.get('password_confirm').value)
      return this.toastrService.show('The password is not the same as confirm it!', 'Error : ', {status: 'danger'});
    if (!this.ImageUpload)
      return this.toastrService.show('Logo not specified!', 'Error : ', {status: 'danger'});
    if (!this.legalRegistrationDocument)
      return this.toastrService.show('Legal Registration document not specified!', 'Error : ', {status: 'danger'});
    if (!this.authorizedSignatoryIdDocument)
      return this.toastrService.show('Authorized Signatory ID not specified!', 'Error : ', {status: 'danger'});
    if (this.businessCategorySelected.length <= 0 )
      return this.toastrService.show('Please select Business Category!', 'Error : ', {status: 'danger'});
    // ===============================
    this.changeStep(3);
  }
  getFormValidationErrors(this_form: any) {
    Object.keys(this_form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.toastrService.show(key + ' : ' + keyError, 'Error : ', {status: 'danger'});
        });
      }
    });
  }

  onForm2Submit() {
    this.showErrorsInForm2 = true;
    if (!this.form2.valid) return;
    // =====================
    this.objectForSave = {
      'name': this.form1.get('name').value,
      'password': this.form1.get('password').value,
      'businessCategories': this.businessCategorySelected,
      'avatar': this.ImageUpload,
      'contactInfo': {
        'address': {
          'country': this.form2.get('country').value,
          'state': this.form2.get('state').value,
          'city': this.form2.get('city').value,
          'street1':  this.form2.get('address').value,
          'postalCode': this.form2.get('postalCode').value,
        },
        'phone': this.form2.get('phone').value,
        'mobile': this.form2.get('mobile').value,
        'fax': this.form2.get('fax').value,
        'email': this.form1.get('email').value,
        'website': this.form2.get('website').value,
        'socialContactItems': {
          'FACEBOOK': this.form2.get('social_instagram').value,
          'INSTAGRAM': this.form2.get('social_facebook').value,
          'LINKED_IN': this.form2.get('social_linkedin').value,
        },
      },
      'about': this.form1.get('about').value,
      'legalRegistrationDocument': this.legalRegistrationDocument,
      'authorizedSignatoryIdDocument': this.authorizedSignatoryIdDocument,
    };
    // ===========================
    this.changeStep(4);
  }
  addBusinessCategory($event) {
    this.businessCategorySelected.push($event.value);
  }
  removeBusinessCategory($event) {
    this.businessCategorySelected.splice(this.businessCategorySelected.indexOf($event.value), 1);
  }

  onSupplierRegister() {
    this.loading = true;
    this.customAuthService.supplierRegister(this.objectForSave).subscribe((res) => {
        this.changeStep(5);
      },
      (error) => {
        this.gloar.checkSubscribeError(error);
        this.loading = false;
        this.ImageUpload = false;
        this.toastrService.show(error.error.message, 'Error : ', {status: 'danger'});
      },
      () => {
        this.loading = false;
      },
    );
  }
  getAddressTree() {
    this.loading = true;
    this.addressTree = [];
    this.addressStatesTree = [] ;
    this.addressCitiesTree = [] ;
    // ==========
    this.form2.get('state').reset();
    this.form2.get('city').reset();
    // ==========
    this.addressesService.tree().subscribe((item: any) => {
        this.addressTree = item ;
      },
      (error) => {
        this.gloar.checkSubscribeError(error);
        this.loading = false;
        this.toastrService.show(error.error.message, 'Error : ', {status: 'danger'});
      },
      () => {
        this.loading = false;
      },
    );
  }

  onChangeCountry($event) {
    this.addressStatesTree = [] ;
    this.addressCitiesTree = [] ;
    // ==========
    this.form2.get('state').reset();
    this.form2.get('city').reset();
    // ==========
    this.addressStatesTree = this.addressTree[$event].states ;
    this.countrySelected = this.addressTree[$event];
  }

  onChangeState($event) {
    this.form2.get('city').reset();
    // ==========
    this.addressCitiesTree = this.addressStatesTree[$event].cities ;
    this.stateSelected = this.addressStatesTree[$event];
  }

  onChangeCity($event) {
    this.citySelected = this.addressCitiesTree[$event];
  }
}
