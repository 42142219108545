import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';
import {LandmarkModel} from '../models/landmark.model';

@Injectable()
export class LandmarksService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private gloarConfig: GloarConfig,
  ) {
  }

  getAllLandmarks(urlQuery) {
    return this.http.get(`${this.serverUrl}landmark/all${urlQuery}`).pipe(map((res) => {
      return res;
    }));
  }

  getAllPublishedLandmarks(urlQuery) {
    return this.http.get(`${this.serverUrl}landmark/allPublished?${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  createLandmark(landmark: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post<any>(`${this.serverUrl}landmark/`, JSON.stringify(landmark), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadImage(landmarkId, image) {
    const input = new FormData();
    input.append('image', image);
    return this.http.post(`${this.serverUrl}landmark/${landmarkId}/uploadImage`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  uploadGallery(landmarkId, image) {
    const input = new FormData();
    for (const item of image) {
      input.append('imageList', item);
    }
    return this.http.post(`${this.serverUrl}landmark/${landmarkId}/uploadGalleryImages`, input, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  removeLandmark(landmarkId) {
    return this.http.delete(`${this.serverUrl}landmark/${landmarkId}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateStatusLandmark(landmarkId, newStatus) {
    return this.http.put(`${this.serverUrl}landmark/${landmarkId}/changeStatusTo${newStatus}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  updateLandmark(landmark: any) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}landmark/`, JSON.stringify(landmark), config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  getLandmarkById(landmarkId: string) {
    return this.http.get<any>(`${this.serverUrl}landmark/${landmarkId}`).pipe(map((res) => {
      return res;
    }));
  }

  publicGetLandmarkById(landmarkId: string) {
    const options = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'true',
      }),
    };
    return this.http.get<any>(`${this.serverUrl}public/landmark/${landmarkId}`, options).pipe(map((res) => {
      return res;
    }));
  }

  removeImageFromGallery(landmarkId: string, imageId: string) {
    return this.http.delete(`${this.serverUrl}landmark/${landmarkId}/deleteGalleryImage/${imageId}`, {})
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
