import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class AddressesService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getCountries() {
    return this.http.get(`${this.serverUrl}address/countries`)
      .pipe(map((res) => {
        return res;
      }));
  }

  getStates(countryId: string) {
    return this.http.get(`${this.serverUrl}address/states/` + countryId)
      .pipe(map((res) => {
        return res;
      }));
  }

  getCities(stateId, cityWord) {
    return this.http.get(`${this.serverUrl}address/cities/${stateId}/` + cityWord)
      .pipe(map((res) => {
        return res;
      }));
  }

  tree() {
    // ==============
    const options = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'true',
        'Content-Type': 'application/json',
      }),
    };
    // =============
    return this.http.get(`${this.serverUrl}address/tree`, options).pipe(map((res) => {
        return res;
      }));
  }
}
