import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {GloarConfig} from '../config/gloar.config';
import {map} from 'rxjs/operators';

@Injectable()
export class ActivitiesBookRequestService {
  serverUrl = this.gloarConfig.params.serverUrl;

  constructor(private http: HttpClient, protected router: Router, private gloarConfig: GloarConfig) {
  }

  getAllBookRequest(urlQuery) {
    return this.http.get(`${this.serverUrl}activity/bookRequest/search${urlQuery}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  changeStatus(activityId, newStatus) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.put(`${this.serverUrl}activity/bookRequest/${activityId}/changeTo${newStatus}`, '', config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }

  forwardToAgent(activityId) {
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http.post(`${this.serverUrl}activity/bookRequest/${activityId}/forward`, '', config)
      .pipe(map((res) => {
          return res;
        }),
      );
  }
}
